<template>
  <BaseDialog v-loading="disableListloading" top="5vh" height="600px" width="888px" :dialogVisible.sync="dialog" :title="title">
    <template>
      <div class="create-proto-list_content">
        <AssignGroupTable
          ref="assignGroupTable"
          :isSelectedMode="isSelectedMode"
          :resetData="selectedData"
          :formField="option.formField"
          :cols="option.cols"
          :form="form"
          :type="type"
          :disAbledList="disAbledList"
        />
      </div>
    </template>

    <template #footer>
      <LoadingBtn @click="cancel"> 取消 </LoadingBtn>
      <el-button v-if="hasDoSubmit" :loading="loading" type="primary" @click="doSubmit"> 确定 </el-button>
    </template>
  </BaseDialog>
</template>
<script>
import AssignGroupTable from './assignGroupTable'
import { commonFromMixin } from '@/mixins'
import { getOption } from '../const'

export default {
  mixins: [commonFromMixin],

  components: {
    AssignGroupTable
  },

  props: {
    selectedData: {
      type: Array,
      default: () => []
    },

    type: {
      type: String,
      default: 'default'
    },

    //是否选择模式
    isSelectedMode: Boolean,

    hasDoSubmit: Boolean
  },

  data() {
    return {
      form: {},
      disAbledList: [],
      disableListloading: false
    }
  },

  created() {
    this.getDisAbledList()
  },

  computed: {
    option({ type }) {
      return getOption(type)
    }
  },

  methods: {
    async validate() {
      const { assignGroupTable } = this.$refs
      return await assignGroupTable.validate()
    },

    /**
     * @description: 获取禁用数据id List
     * @param {*}
     * @return {*}
     */
    async getDisAbledList() {
      const { getDisAbledList } = this.option
      if (!getDisAbledList) return
      this.disableListloading = true
      const data = await getDisAbledList()
      this.disableListloading = false
      this.disAbledList = data.map(({ id }) => id)
    },

    async doSubmit() {
      if (!(await this.validate())) return
      try {
        this.loading = true
        const { assignGroupTable } = this.$refs
        const [err, data] = await assignGroupTable.getAllSelectedDataData(this.selectedData)
        if (err) return
        const value = this.shakingDisable(data).map(({ id }) => id)
        if (!value.length) return this.$message.warning('至少需要选择一个已上架的数据！')
        const [err1] = await this.doFunc(value)
        if (err1) return
        this.success()
        this.$emit('successHandler', value)
      } finally {
        this.loading = false
      }
    },

    /**
     * @description: 去除禁用的数据id
     * @param {*} data
     * @return {*}
     */
    shakingDisable(data) {
      return  data.filter(({ id }) => !this.disAbledList.find((dId) => dId == id))
    },

    async doFunc(value) {
      try {
        const { addSubmitFuncApi } = this.option
        const { code, detail } = await addSubmitFuncApi.call(this, value)
        return [!$SUC({ code }), detail || []]
      } catch (err) {
        return [true, []]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.create-proto-list_content {
  height: 30vh;
}
</style>